@import url("https://fonts.googleapis.com/css?family=Gochi+Hand");

.logo > * > * > path {
  stroke-dasharray: 3000;
  stroke-dashoffset: 0;
  animation: dash 5s linear normal 1 forwards;
}

@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
